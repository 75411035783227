@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  background-color: #ffffff;
  background-image: url(../../../assets/images/crack-upsc.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 24px;
  max-width: 1080px;
  margin: 0 auto 48px auto;
  font-family: 'Avenir';
  gap: 35px;
  margin-top: 35px;

  @include respond(tablet) {
    flex-direction: column;
    background-image: none;
    gap: 16px;
    margin-bottom: 28px;
    @include respond(mobile) {
      box-shadow: unset;
      padding: 0px 16px 0px 16px;
    }
  }

  .leftSection {
    flex: 1 0 50%;

    .titleWrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 48px;
      gap: 12px;

      @include respond(tablet) {
        margin-bottom: 0px;
      }

      .title {
        color: var(--alias-grayscale-header-weak, #262338);
        font-family: Avenir;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        width: 319px;

        @include respond(tablet) {
          font-size: 16px;
          line-height: 20px;
        }
        @include respond(mobile) {
          width: 180px;
        }

        span {
          color: #423DCF;
        }
      }

      .subtitle {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;

        @include respond(tablet) {
          font-size: 12px;
          line-height: 150%;
        }

        b {
          color: #fb9c46;
          font-weight: normal;
        }
      }
    }

    .topEducatorsWrapper {
      display: flex;
      flex-direction: column;
      max-width: 320px;

      @include respond(tablet) {
        display: none;
      }

      .topEducators {
        margin-bottom: 12px;
      }

      .title {
        display: block;
        color: var(--alias-grayscale-header-weak, #262338);
        font-family: "Avenir";
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin-bottom: 8px;
      }

      .subtitle {
        color: var(--alias-grayscale-body, #4E4B66);
        font-family: "Avenir";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }

  .rightSection {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    @include respond(tablet) {
      overflow-x: auto;
      padding: 5px;
    }

    .courseItemsWrapper {
      display: grid;
      gap: 16px;
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: 1fr;
      grid-auto-flow: column;

      @include respond(tablet) {
        margin: 0px !important;
        grid-template-columns: minmax(220px, 1fr);
      }

      &:first-child {
        margin-top: 42px;
      }

      &:last-child {
        margin-bottom: 42px;
      }
    }
  }
}
